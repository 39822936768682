import styled, { css } from "styled-components";

import { FooterStyledProps as Props } from "./Footer.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const sharedTextStyles = css`
  font-family: inherit;
  color: var(--palette-primary);
`;

const FooterStyled = styled.div<Props>`
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  background-color: var(--palette-white);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas: ". content .";

  @media (min-width: ${mobile}px) {
    padding-top: 4.8rem;
  }

  .Footer {
    &__content {
      grid-area: content;
    }

    &__top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 1.6rem;

      @media (min-width: ${mobile}px) {
        flex-direction: row;
      }
    }

    &__row {
      &:not(:last-child) {
        padding-bottom: 1.6rem;
      }

      &:first-child {
        padding-bottom: 4.8rem;
      }
    }

    &__divider {
      margin-top: 8.6rem;
      margin-bottom: 3.2rem;
      height: 0.1rem;
      background-color: var(--palette-gray-h220-s06-l90);
    }

    &__labels {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2rem;

      @media (max-width: ${tablet}px) {
        margin-top: 1.2rem;
      }

      &__item {
        margin-bottom: 2rem;
        text-align: right;
        ${sharedTextStyles}

        &--phone {
          text-decoration: none;
        }

        &--mini {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: var(--palette-black-h60-s25-l2-a10);
          text-align: right;

          &:not(:last-child) {
            margin-bottom: 0.6rem;
          }
        }
      }
    }

    &__bottom {
      &__privacy-and-terms {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 400;

        &__item {
          text-decoration: none;
          cursor: pointer;
          text-align: center;
          ${sharedTextStyles}
          color: var(--palette-gray-s0-l50);

          &:not(:last-child)::after {
            content: " | ";
          }

          &:not(:first-child) {
            padding-left: 0.2rem;
          }
        }

        @media (min-width: ${tablet}px) {
          &.Footer__row {
            padding-bottom: 1.6rem;
          }
        }
      }

      @media (min-width: ${tablet}px) {
        display: none;
      }

      &--large {
        display: none;

        &-content {
          display: flex;
          justify-content: space-between;
        }

        @media (min-width: ${tablet}px) {
          display: block;
        }
      }
    }

    &__row.Footer__bottom__copyright {
      font-size: 1.6rem;
      color: var(--palette-gray-s0-l50);
      padding-bottom: 0.4rem;
      font-weight: 400;
    }

    &__row.Footer__bottom__developer {
      padding-left: 1.6rem;
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l50);
    }

    &__bottom__developer-company {
      font-weight: 800;
    }
  }
`;

export default FooterStyled;
